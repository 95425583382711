<template>
    <Layout>
        <div class="row" id="page">
            <div class="col">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title">
                            Resposta da Atividade
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-label" v-html="answer.content.activity.description"></div>
                            </div>
                            <div class="col-12 mb-2" v-if="answer.answer && answer.content.activity.type === 1">
                                {{ answer.answer }}
                            </div>

                            <div class="col-12 mb-2" v-if="answer.answer && answer.content.activity.type === 4">
                                <a :href="`${env.url}activity-answer/file/${answer.id}`" target="_blank">
                                    <i class="fa fa-download pointer"></i>
                                </a>
                            </div>

                            <div class="col-12 mb-2" v-for="(alternative, i) in answer.content.activity.alternatives"
                                 :key="'alternative-' + alternative.id">
                                <i class="fa fa-check-circle text-success"
                                   v-if="answerAlternatives.indexOf(alternative.id) > -1"></i>
                                <span v-else>{{ alphabet[i] }}) </span>
                                <span class="italic"
                                      :class="answerAlternatives.indexOf(alternative.id) > -1 ? 'fw-bold' : ''">
                                    {{ alternative.alternative }}
                                </span>
                            </div>

                            <div class="col-12 mb-3 mt-5">
                                <label for="feedback" class="form-label">Feedback <span
                                    class="text-danger">*</span></label>
                                <textarea id="feedback" v-model="feedback" rows="10" class="form-control"></textarea>
                            </div>
                            <div class="col-12 text-end mb-3">
                                <button type="button" class="btn btn-outline-primary" @click="save">Salvar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>

import Layout from "@/components/layout/main.vue";
import http from '@/http'
import {endLoading, startLoading} from "@/components/composables/spinners";
import env from '@/env'

export default {
    components: {Layout},
    data() {
        return {
            answer: {
                content: {
                    activity: ''
                }
            },
            feedback: '',
            activity_id: 0,
            alphabet: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
            answerAlternatives: [],
            env,
        }
    },

    methods: {
        getData() {
            this.$store.commit('api/setSpinner', true);
            startLoading('page');
            const formData = {
                mentee: this.$route.params.mentee,
                slug: 'm/' + this.$route.params.slug,
                content: this.$route.params.content,
            }
            http.post('activity-answer/get', formData)
                .then(response => {
                    this.answer = response.data
                    this.feedback = response.data.feedback

                    response.data.alternatives.forEach(el => {
                        this.answerAlternatives.push(el.activity_alternative_id);
                    })
                })
                .catch(e => {
                    console.error('Get Tasks Mentoring: ', e)
                })
                .finally(() => {
                    this.$store.commit('api/setSpinner', false);
                    endLoading('page');
                })
        },

        save() {

            const formData = {
                feedback: this.feedback,
                id: this.answer.id,
            }

            http.post('activity-answer/feedback', formData)
                .then(() => {
                    this.$notifySuccess('Feedback salvo com sucesso!');
                    setTimeout(() => {
                        this.$router.go(-1);
                    }, 500)
                })
                .catch(e => {
                    console.error('Get Tasks Mentoring: ', e)
                })
        }
    },

    mounted() {
        this.getData();
    }
}

</script>
